"use client";
import css from "./styles.module.scss";
import Image from "next/image";
import Mail from "../../assets/icons/mail.svg";
import Phone from "../../assets/icons/phone.svg";
import DownArrow from "../../assets/icons/arrow down.svg";
import Logo from "../../assets/icons/logo.svg";
import Link from "next/link";
import { usePathname } from "next/navigation";
import clsx from "clsx";
import { useState } from "react";
import Menu from "../../assets/icons/menu.svg";
import i18n from "../../i18n/i18n";
import { useTranslation } from "react-i18next";

export const NewHeader = () => {
  const { t } = useTranslation("header");
  const menu = [
    {
      title: t("header1"),
      href: "/",
    },
    {
      title: t("header2"),
      href: "/aboutUs",
    },
    {
      title: t("header3.maintitle"),
      href: "/equipmentMachinery",
    },
    {
      title: t("header4"),
      href: "/mainProducts",
    },
    {
      title: t("header5"),
      href: "/news",
    },
    {
      title: t("header6"),
      href: "/contactUs",
    },
    {
      title: t("header7"),
      href: "/FAQ",
    },
  ];

  const menuMobile = [
    {
      title: t("header1"),
      href: "/",
    },
    {
      title: t("header2"),
      href: "/aboutUs",
    },
    {
      title: t("header3.subtitle1"),
      href: "/equipmentMachinery/equipment",
    },
    {
      title: t("header3.subtitle2"),
      href: "/equipmentMachinery/certificate",
    },
    {
      title: t("header4"),
      href: "/mainProducts",
    },
    {
      title: t("header5"),
      href: "/news",
    },
    {
      title: t("header6"),
      href: "/contactUs",
    },
    {
      title: t("header7"),
      href: "/FAQ",
    },
  ];

  const [isSubMenuShow, setIsSubMenuShow] = useState(false);
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
  const [isShowLanguageModal, setIsShowLanguageModal] = useState(false);

  const currentLanguage = i18n.language;
  const pathName = usePathname();
  const onChangeLanguage = (lng: "vi" | "en") => {
    i18n.changeLanguage(lng);
    setIsShowLanguageModal(false);
  };

  return (
    <div className={css.HeaderWrapper}>
      <div className={css.HeaderTop}>
        <div className={css.HeaderLeftInfo}>
          <Image src={Mail} alt="mail" className={css.Icon} />
          casakinhdoanh@gmail.com
          <div className={css.HideMobile}>
            <div className={css.Divider} />
            <Image src={Phone} alt="mail" className={css.Icon} />
            +84 91 913 20 62
          </div>
        </div>

        <div className={css.HeaderRightInfo}>
          <div
            onClick={() => setIsShowLanguageModal(!isShowLanguageModal)}
            className={css.HeaderRightInfoClick}
          >
            {currentLanguage === "vi" ? "Vietnamese" : "English"}
            <Image src={DownArrow} alt="mail" className={css.Icon} />
          </div>

          {isShowLanguageModal && (
            <div className={css.SelectLanguageWrapper}>
              <div
                onClick={() => onChangeLanguage("vi")}
                className={css.SelectLanguageItem}
              >
                Vietnamese
              </div>
              <div
                onClick={() => onChangeLanguage("en")}
                className={css.SelectLanguageItem}
              >
                English
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={css.HeaderBottom}>
        <Image
          onClick={() => setIsShowMobileMenu(!isShowMobileMenu)}
          alt="menu"
          width={24}
          height={24}
          src={Menu}
          className={css.MenuMobile}
        />
        <Link href={"/"}>
          <Image
            width={83}
            height={40}
            src={Logo}
            alt="logo"
            className={css.Logo}
          />
        </Link>

        <div
          className={clsx({
            [css.MobileMenu]: true,
            [css.MobileMenuShow]: isShowMobileMenu,
          })}
        >
          {menuMobile.map((item) => (
            <Link
              onClick={() => setIsShowMobileMenu(false)}
              className={clsx({
                [css.MobileMenuItem]: true,
                [css.MobileMenuItemActive]: pathName == item.href,
              })}
              href={item.href}
              key={item.href}
            >
              {item.title}
            </Link>
          ))}
        </div>
        <div className={css.NavBarWrapper}>
          {menu.map((item) => (
            <div className={css.LinkWrapper} key={item.href}>
              {item.href === "/equipmentMachinery" ? (
                <div
                  className={clsx({
                    [css.NavBarItem]: true,
                    [css.NavBarItemActive]:
                      pathName == "/equipmentMachinery/equipment" ||
                      pathName == "/equipmentMachinery/certificate",
                    [css.EquipmentMachinery]: true,
                  })}
                  onClick={() => setIsSubMenuShow(!isSubMenuShow)}
                >
                  <div
                    className={clsx({
                      [css.SubMenu]: true,
                      [css.SubMenuShow]: isSubMenuShow,
                    })}
                  >
                    <Link
                      className={css.SubMenuItem}
                      href={"/equipmentMachinery/equipment"}
                    >
                      {t("header3.subtitle1")}
                    </Link>
                    <Link
                      className={css.SubMenuItem}
                      href={"/equipmentMachinery/certificate"}
                    >
                      {t("header3.subtitle2")}
                    </Link>
                  </div>
                  {item.title}
                </div>
              ) : (
                <Link
                  onClick={() => setIsSubMenuShow(false)}
                  className={clsx({
                    [css.NavBarItem]: true,
                    [css.NavBarItemActive]: pathName == item.href,
                  })}
                  href={item.href}
                >
                  {item.title}
                </Link>
              )}
              {
                <div
                  style={
                    pathName === item.href ||
                    pathName === `${item.href}/equipment` ||
                    pathName === `${item.href}/certificate`
                      ? {}
                      : { visibility: "hidden" }
                  }
                  className={css.BottomWhite}
                />
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
