import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HOME_EN from "../locales/en/home.json";
import HOME_VI from "../locales/vi/home.json";
import ABOUTUS_EN from "../locales/en/aboutUs.json";
import ABOUTUS_VI from "../locales/vi/aboutUs.json";
import CERT_EN from "../locales/en/cert.json";
import CERT_VI from "../locales/vi/cert.json";
import EQUIPMENT_EN from "../locales/en/equipment.json";
import EQUIPMENT_VI from "../locales/vi/equipment.json";
import NEW_EN from "../locales/en/new.json";
import NEW_VI from "../locales/vi/new.json";
import CONTACT_EN from "../locales/en/contact.json";
import CONTACT_VI from "../locales/vi/contact.json";
import FAQ_EN from "../locales/en/faq.json";
import FAQ_VI from "../locales/vi/faq.json";
import MAIN_PRODUCT_EN from "../locales/en/mainProduct.json";
import MAIN_PRODUCT_VI from "../locales/vi/mainProduct.json";
import HEADER_EN from "../locales/en/header.json";
import HEADER_VI from "../locales/vi/header.json";
import FOOTER_EN from "../locales/en/footer.json";
import FOOTER_VI from "../locales/vi/footer.json";

export const resources = {
  en: {
    home: HOME_EN,
    aboutUs: ABOUTUS_EN,
    cert: CERT_EN,
    equipment: EQUIPMENT_EN,
    new: NEW_EN,
    contact: CONTACT_EN,
    mainProducts: MAIN_PRODUCT_EN,
    faq: FAQ_EN,
    header: HEADER_EN,
    footer: FOOTER_EN,
  },
  vi: {
    home: HOME_VI,
    aboutUs: ABOUTUS_VI,
    cert: CERT_VI,
    equipment: EQUIPMENT_VI,
    new: NEW_VI,
    contact: CONTACT_VI,
    mainProducts: MAIN_PRODUCT_VI,
    faq: FAQ_VI,
    header: HEADER_VI,
    footer: FOOTER_VI,
  },
};

export const defaultNS = "home";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: [
      "home",
      "aboutUs",
      "cert",
      "equipment",
      "new",
      "contact",
      "faq",
      "header",
      "footer",
    ],
    defaultNS,
    lng: "en",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
