"use client";
import { useRouter } from "next/navigation";
import React, {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { ACCESS_TOKEN_KEY, IS_LOGIN_KEY } from "@/constants/auth";
import { URLPage } from "@/enums/url";
import { getProducts } from "@/api/product";
import { TProduct } from "@/components/pages/mainProducts/mainProductsList";

export const AppContext = createContext<{
  token: string | null;
  setToken?: Dispatch<SetStateAction<string | null>>;
  setIsLogin?: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  isLogin?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUser?: Dispatch<SetStateAction<any>>;
  onLogoOut?: () => void;
  onLogin?: (accessToken: string) => void;
  products: TProduct[];
  isLoading: boolean;
}>({
  token: null,
  user: null,
  products: [],
  isLoading: false,
});

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const [token, setToken] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [user, setUser] = useState<any>(null);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [products, setProducts] = useState<TProduct[]>([]);

  const handleGetProducts = async () => {
    setIsLoading(true);
    const data = await getProducts({
      restaurant_id: "5d5c2d2f-bd86-411c-bcc8-df44f3ff6436",
      limit: 5000,
      offset: 0,
    });
    setProducts(data.records);
    setIsLoading(false);
  };
  useEffect(() => {
    handleGetProducts();
  }, []);

  const onLogin = (token: string | null) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    localStorage.setItem(IS_LOGIN_KEY, true as any);
    localStorage.setItem(ACCESS_TOKEN_KEY, token as string);
    setToken(token);
    setIsLogin(true);
    router.push(URLPage.ADMIN);
  };

  const onLogoOut = () => {
    localStorage.removeItem(IS_LOGIN_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    setToken(null);
  };

  return (
    <AppContext.Provider
      value={{
        token,
        isLogin,
        user,
        setToken,
        setIsLogin,
        setUser,
        onLogoOut,
        onLogin,
        products,
        isLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
