"use client";

import css from "./style.module.scss";
import FooterImg from "../../assets/images/FooterImage.png";
import Image from "next/image";
import Logo from "../../assets/icons/logo.svg";
import Facebook from "../../assets/icons/Facebook.svg";
import Instagram from "../../assets/icons/Instagram.svg";
import Youtube from "../../assets/icons/Youtube.svg";
import TikTok from "../../assets/icons/TikTok.svg";
import Icon1 from "../../assets/images/Icon1.png";
import Icon2 from "../../assets/images/Icon2.png";
import Icon3 from "../../assets/images/Icon3.png";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation("footer");
  return (
    <div className={css.Wrapper}>
      <div className={css.FooterImage}>
        <Image src={FooterImg} alt="Footer Image" />
      </div>
      <div className={css.Container}>
        <Image
          width={140}
          height={70}
          src={Logo}
          alt="logo"
          className={css.Logo}
        />
        <div className={css.Box}>
          <div className={css.FooterInfo}>
            <div className={css.Column1}>
              <div className={css.BoxIcon}>
                <Image src={Icon1} className={css.Icon1} alt="Icon1" />
                <div className={css.TextVenue}>
                  <p>{t("address")}</p>
                  <p>{t("addressDetail")}</p>
                </div>
              </div>
              <div className={css.BoxIcon}>
                <Image src={Icon2} alt="Icon2"></Image>
                casakinhdoanh@gmail.com
              </div>
              <div className={css.BoxIcon}>
                <Image src={Icon3} alt="Icon3"></Image>
                +84 91 913 20 62
              </div>
            </div>
            <div className={css.Column2Wrapper}>
              <div className={css.Column2}>
                <div className={css.TitleColumn}>{t("product")}</div>
                {/*<div className={css.ItemColumn}>lorem ipsum</div>
                <div className={css.ItemColumn}>lorem ipsum</div>
                <div className={css.ItemColumn}>lorem ipsum</div>*/}
              </div>
              <div className={css.Column2}>
                <div className={css.TitleColumn}>{t("news")}</div>
                {/*<div className={css.ItemColumn}>lorem ipsum</div>
                <div className={css.ItemColumn}>lorem ipsum</div>
                <div className={css.ItemColumn}>lorem ipsum</div>*/}
              </div>
              <div className={css.Column2}>
                <div className={css.TitleColumn}>{t("contact")}</div>
                {/*<div className={css.ItemColumn}>lorem ipsum</div>
                <div className={css.ItemColumn}>lorem ipsum</div>
                <div className={css.ItemColumn}>lorem ipsum</div>*/}
              </div>
            </div>
          </div>
          <div className={css.ContactBox}>
            <div className={css.Text}>Follow us on</div>
            <div className={css.NetworkList}>
              <Image alt="" src={Facebook} className={css.NetworkItem}></Image>
              <Image alt="" src={Youtube} className={css.NetworkItem}></Image>
              <Image alt="" src={Instagram} className={css.NetworkItem}></Image>
              <Image alt="" src={TikTok} className={css.NetworkItem}></Image>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
